import React, { useEffect, useState, useRef } from "react";
import style from './index.module.less'
import { CompatibilityWire, CompatibilitySupport, CompatibilityDevice } from '@/components/index'
import {
    ipadIcon,
    appleIcon,
    androidPhoneIcon,
    steamIcon,
    switchIcon,
    ps5Icon,
    xboxIcon,
    windowIcon,
    macIcon
} from '@/images/index'
import {
    mobileSubClose,
} from '@/images/menu/index'
//type:0 上面左右排列的紫色文字，下面列表
//type:1 上下中间有个横线的
//type:2 有线无线
//type:3 新改版mirroView
import { useIntl } from "react-intl"
import useBigScreen from "@/hooks/bigScreen"
import { getCompatibilityCode } from '@/utils/regions.js'


const DetailCompatibility = ({ tabArr, list1 }) => {
    const compCode = getCompatibilityCode()

    const intl = useIntl()
    const ifPc = useBigScreen(900)
    const [tabNum, setTabNum] = useState(0)
    const [mobileArr, setMobileArr] = useState(tabArr)
    const [ifFloat, setIfFloat] = useState(false)
    const [ifMobileOpen, setIfMobileOpen] = useState(false)

    const tabRef = useRef()
    const tabMobileRef = useRef()
    

    useEffect(() => {
        window.addEventListener('scroll', getTabY)

        return () => {
            window.removeEventListener('scroll', getTabY)
        }
    }, [ifPc])

    let lastScrollTop = 0;

    const getTabY = () => {
        if (ifPc) {
            let tabY = tabRef.current.getBoundingClientRect().y
            if (tabY <= 70) {
                setIfFloat(true)
            } else {
                setIfFloat(false)
            }
        }
        const currentScrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        const scrollDistance = currentScrollTop - lastScrollTop
        lastScrollTop = currentScrollTop;
        //判断是否上滑
        if (scrollDistance <= 0) {

            if (ifPc) {
                tabRef.current.style.top = '70px'
            } else {
                tabMobileRef.current.style.top = '70px'
            }
        } else {
            if (ifPc) {
                tabRef.current.style.top = '20px'
            } else {
                tabMobileRef.current.style.top = '20px'
            }

        }


    }

    const clickMobile = (value) => {
        let arr = [...tabArr]
        let valueIndex = arr.indexOf(value)
        setTabNum(valueIndex)
        var removed = arr.splice(valueIndex, 1)[0]
        arr.unshift(removed);
        setMobileArr(arr)

    }

    const connectList1 = [
        {
            img: androidPhoneIcon,
            name: intl.formatMessage({ id: "compatibility_android", defaultMessage: "Android Devices" })
        },
        {
            img: ipadIcon,
            name: "iPad"
        },
        {
            img: windowIcon,
            name: "Windows"
        },
        {
            img: macIcon,
            name: "MacBook"
        },
        {
            img: steamIcon,
            name: "Steam Deck"
        }
    ]
    const connectList2 = [
        {
            img: appleIcon,
            name: "iPhone/iPad"
        },
        {
            img: switchIcon,
            name: "Nintendo Switch"
        },
        {
            img: ps5Icon,
            name: "PlayStation 5"
        },
        {
            img: xboxIcon,
            name: "Xbox"
        }
    ]

    return (
        <div className={style.box}>
            <div className={style.title}>{intl.formatMessage({ id: "support_how_connect", defaultMessage: "Compatibility" })}</div>

            {ifPc ? <div className={style.tab} ref={tabRef}
                style={{
                    backgroundColor: ifFloat ? 'rgba(233, 233, 233, 0.50)' : 'transparent',
                    backdropFilter: ifFloat ? 'blur(8px)' : "none",
                    WebkitBackdropFilter: ifFloat ? 'blur(8px)' : "none"
                }}
            >
                {tabArr.map((item, index) => (
                    <div className={style.tabItem} key={`supportTab${index}`}
                        style={{
                            color: tabNum == index ? '#000' : "rgba(0, 0, 0, 0.6)",
                            backgroundColor: tabNum == index && ifFloat ? 'rgba(0, 0, 0, 0.10)' : 'transparent',

                        }}
                        onClick={() => setTabNum(index)}
                    >{item}
                        <span style={{ opacity: tabNum == index && !ifFloat ? '1' : "0" }}></span>
                    </div>
                ))}
            </div> :
                <div className={style.tabMobile}
                    ref={tabMobileRef}
                    onClick={() => { setIfMobileOpen(!ifMobileOpen) }}
                    style={{ height: ifMobileOpen ? '258px' : "60px" }}
                >
                    <div className={style.tabMobileBox}>
                        {mobileArr.map((item, index) => (
                            <div className={style.tabMobileBoxItem} key={`supportTabMobile${index}`}
                                style={{ color: index == 0 ? "#111" : "rgba(0,0,0,0.4)" }}
                                onClick={() => index !== 0 && clickMobile(item)}
                            >
                                {item}
                                {index == 0 && <img src={mobileSubClose}
                                    style={{ transform: !ifMobileOpen ? 'rotate(0deg)' : 'rotate(180deg)' }}
                                />}
                            </div>
                        ))}
                    </div>

                </div>
            }
            <div className={style.show}>
                {tabNum == 0 && <div >
                    <div className={style.compatibilityTypeTitle}>{intl.formatMessage({ id: "compatibility_text1", defaultMessage: "Directly connect to any device with USB-C video output" })}</div>
                    <CompatibilityDevice type='else' elseList={connectList1} />
                    <div className={style.compatibilityTypeTitle2}>{intl.formatMessage({ id: "compatibility_text2", defaultMessage: "Connect via XREAL Adapter to Lightning and HDMI ports" })}</div>
                    <CompatibilityDevice type='else' elseList={connectList2} />

                </div>}
                {tabNum == 1 && <CompatibilityWire />}
                {tabNum == 2 && <CompatibilitySupport list={list1} />}
            </div>

        </div>
    )
}

export default DetailCompatibility